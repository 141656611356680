<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.services") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          b
          justify-content-end
          w-reset
        "
      >
        <el-button size="mini" @click="drawer.create.status = true">
          <i class="el-icon-circle-plus-outline"></i>
          {{ $t("message.create") }}
        </el-button>
        <export-excel
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          :worksheet="$t(`message.services`)"
          name="Услуги.xls"
        >
          <el-button size="mini">
            <i class="el-icon-document-delete"></i> Excel
          </el-button>
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'services'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.name"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.code"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.is_service"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.price"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.discount_price"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
              :sort="sort"
              :column="columns.service_category"
              @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.resident_price"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
              :column="columns.status"
              :sort="sort"
              @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.updated_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.name.show">
            <el-input
              size="mini"
              v-model="filterForm.name"
              :placeholder="columns.name.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.code.show">
            <el-input
              size="mini"
              v-model="filterForm.code"
              :placeholder="columns.code.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.is_service.show">
            <el-select
              filterable
              clearable
              :placeholder="columns.is_service.title"
              size="mini"
              v-model="filterForm.is_service"
            >
              <el-option
                v-for="item in categories"
                :key="item.name + item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.price.show">
            <el-input
              size="mini"
              v-model="filterForm.price"
              :placeholder="columns.price.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.discount_price.show">
            <el-input
              size="mini"
              v-model="filterForm.discount_price"
              :placeholder="columns.discount_price.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.service_category.show">
            <el-select
                filterable
                clearable
                :placeholder="columns.service_category.title"
                size="mini"
                v-model="filterForm.service_type_id"
            >
              <el-option
                  v-for="item in categories"
                  :key="item.name + item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.resident_price.show">
            <el-input
              size="mini"
              v-model="filterForm.resident_price"
              :placeholder="columns.resident_price.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.status.show">
            <el-select
                filterable
                clearable
                :placeholder="columns.status.title"
                size="mini"
                v-model="filterForm.enabled"
            >
              <el-option
                  :label="$t('message.enabled')"
                  :value="true"
              ></el-option>
              <el-option
                  :label="$t('message.disabled')"
                  :value="false"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.updated_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="service in list"
          :key="service.id"
          :style="`background: ${service.color}`"
        >
          <td v-if="columns.id.show">{{ service.id }}</td>
          <td v-if="columns.name.show">
            {{ service.name | uppercaseFirst }}
          </td>
          <td v-if="columns.code.show">{{ service.code }}</td>
          <td v-if="columns.is_service.show">
            {{ service.is_service }}
          </td>
          <td v-if="columns.price.show">{{ service.price }}</td>
          <td v-if="columns.discount_price.show">
            {{ service.discount_price }}
          </td>
          <td v-if="columns.service_category.show">
            {{ service.category ? service.category.name : null}}
          </td>
          <td v-if="columns.resident_price.show">
            {{ service.resident_price }}
          </td>
          <td v-if="columns.status.show">
            {{ service.enabled ? $t('message.enabled') : $t('message.disabled') }}
          </td>
          <td v-if="columns.updated_at.show">
            {{ service.updated_at }}
          </td>
          <td v-if="columns.created_at.show">
            {{ service.created_at }}
          </td>
          <td>
            <crm-setting-dropdown
              :model="service"
              name="services"
              :actions="actions"
              @edit="edit"
              @delete="destroyModel"
            >
            </crm-setting-dropdown>
          </td>
        </tr>
      </transition-group>
    </table>

    <el-drawer
      size="90%" :wrapperClosable="false"
      :visible.sync="drawer.create.status"
      :ref="drawer.create.name"
      @opened="drawerOpened(drawer.create.component)"
      @closed="drawerClosed(drawer.create.component)"
    >
      <crm-create
        :ref="drawer.create.component"
        :drawer-name="drawer.create.name"
      ></crm-create>
    </el-drawer>

    <el-drawer
      size="90%" :wrapperClosable="false"
      :visible.sync="drawer.update.status"
      :ref="drawer.update.name"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <crm-update
        :selected="selectedModel"
        :ref="drawer.update.component"
        :drawer-name="drawer.update.name"
      >
      </crm-update>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [list, drawer],
  name: "ServicesList",
  components: {
    CrmUpdate,
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      selectedModel: {},
      excel_fields: {},
      loadingData: false,
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "services/list",
      columns: "services/columns",
      pagination: "services/pagination",
      filter: "services/filter",
      sort: "services/sort",
      categories: "categoryServices/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    this.controlExcelData();

    if (this.categories && this.categories.length === 0)
      await this.loadCategories();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateSort: "services/updateSort",
      updateFilter: "services/updateFilter",
      updateColumn: "services/updateColumn",
      updateList: "services/index",
      updatePagination: "services/updatePagination",
      editModel: "services/show",
      empty: "services/empty",
      delete: "services/destroy",
      refreshData: "services/refreshData",
      loadCategories: "categoryServices/index",
    }),
    fetchData() {
      this.$loadingCursor("wait");
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.$loadingCursor("default");
            this.loadingData = false;
          })
          .catch((err) => {
            this.$loadingCursor("default");
            this.loadingData = false;
          });
      }
    },

    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    edit(model) {
      this.selectedModel = model;
      this.drawer.update.status = true;
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    destroyModel(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
